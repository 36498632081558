<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('withdraw.head') }}</h2>
    </v-col>
    <v-col cols="12" v-if="canCreate">
      <v-form ref="form" v-model="formValid">
        <v-card>
          <v-card-title> {{ $t('withdraw.head_list1') }} </v-card-title>

          <v-card-actions class="d-flex align-start">
            <v-row no-gutters style="width: 100px">
              <v-autocomplete
                v-debounce:500ms="searchUser"
                v-model="memberId"
                :placeholder="$t('withdraw.please_select')"
                dense
                :items="members"
                :rules="[v => !!v || '']"
                item-text="label"
                :loading="loadingMember"
                :disabled="loadingMember"
                outlined
                hide-details
              ></v-autocomplete>
              <!-- <v-select label="Username" dense outlined /> -->
              <v-text-field
                :label="$t('withdraw.amount')"
                v-model="amount"
                text="number"
                :rules="[v => !!v || '']"
                :placeholder="$t('withdraw.amount2')"
                dense
                outlined
              />
              <v-select
                :label="$t('withdraw.transaction')"
                v-model="transaction"
                :items="saveTransactions"
                item-text="name"
                dense
                outlined
              />
              <v-card color="#EEEEEE" width="300px">
                <v-card-text> {{ $t('withdraw.condition1') }} </v-card-text>
                <v-divider class="mx-3" />
                <v-card-text> {{ $t('withdraw.condition2') }} </v-card-text>
                <v-card-text> {{ $t('withdraw.condition3') }} </v-card-text>
              </v-card>
            </v-row>
          </v-card-actions>
          <v-divider class="px-3" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="success" @click="withdraw" :loading="loadingSubmit" class="white--text">
              <v-icon small class="px-2">mdi-content-save-outline</v-icon>
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('withdraw.head_list2') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input
                ref="startDate"
                :date="dateFrom"
                :label="$t('withdraw.date_from')"
                @setValue="dateFrom = $event"
              />
            </span>
            <span>
              <date-picker-input
                ref="endDate"
                :date="dateTo"
                :label="$t('withdraw.date_to')"
                @setValue="dateTo = $event"
              />
            </span>
            <v-btn color="primary" @click="searchWithdraw" class="px-5"> {{ $t('search') }} </v-btn>
            <!-- <v-btn color="warning" @click="preloadExportCsv" class="px-5">{{ $t('deposit_report.export_excel') }}</v-btn> -->
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-row no-gutters>
            <v-spacer />
            <span class="px-3 mt-5">{{ $t('search') }}:</span>
            <div style="width: 150px" class="mt-5">
              <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
            </div>
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table
            mobile-breakpoint="0"
            style="width: 100%"
            :headers="headers"
            :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')"
            :options.sync="pagination"
            :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage"
            :loading="loadingTable"
            :page.sync="pagination.page"
            :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }"
            :items="itemsData"
          >
            <template v-slot:[`item.bank`]="{ item }">
              <div class="ma-auto" style="width: 24px;height: 24px;aspect-ratio: 1;display: grid;">
                <div v-if="!item.bankCountry">
                  <img v-if="item.bank" alt="bank_img"
                    style="object-fit: contain;height: inherit;width: 100%;"
                    :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
                </div>
                <div v-else>
                  <img v-if="item.bank" alt="bank_img"
                    style="object-fit: contain;height: inherit;width: 100%;"
                    :src="`https://www.ak47.services/akasset-v2/banks/${item.bankCountry}/${item.bank}.webp`" />
                </div>
              </div>
              <span>{{ item.bankAccount }}</span>

              <!-- <img
                v-if="item.bank && item.bank !== 'RBS'"
                class="pt-3"
                alt="Avatar"
                width="25px"
                :src="require(`@/assets/images/logos/banks/${item.bank}.png`)"
              />
              <br />
              <span>{{ item.bankAccount }}</span> -->
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span>{{ item.amount | currency }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-select
                v-if="item.status === 'wait' && canCreate"
                placeholder=""
                v-model="item.status"
                :items="item.bank === 'TRUEWALLET' ? statusItemsTmn2 : statusItems.filter(item => item.value !== 'processing')"
                outlined
                dense
                @change="changeWithdraw(item)"
                hide-details
                :readonly="item.status !== 'wait'"
              />
              <span v-else>
                <v-btn :class="getColor(item.status)" class="white--text" v-if="item.status !== 'wait'">
                  {{ getText(item.status, item.bank, item.paykrub) }}
                </v-btn>
              </span>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" dark v-bind="attrs" v-on="on">
                    <v-icon small color="white">mdi-square-edit-outline</v-icon>
                    <span class="px-3 white--text">{{ $t('withdraw.manage') }}</span>
                    <v-icon small color="white">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="withdrawForm(item)">
                    <v-list-item-title>
                      <v-icon small color="success">mdi-magnify</v-icon>
                      <span class="px-4">{{ $t('withdraw.check') }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      formValid: true,
      memberId: '',
      amount: '',
      transaction: true,
      searchData: null,
      saveTransactions: [
        {
          name: this.$t('yes'),
          value: true,
        },
        {
          name: this.$t('not'),
          value: false,
        },
      ],
      statusItemsTmn: [
        {
          text: this.$t('withdraw.status1'),
          value: 'wait',
          color: '',
        },
        {
          text: this.$t('withdraw.status6'),
          value: 'success_1',
          color: 'success',
        },
        {
          text: this.$t('withdraw.status3'),
          value: 'success_0',
          color: 'info',
        },
        {
          text: this.$t('withdraw.status4'),
          value: 'cancel',
          color: 'error',
        },
      ],
      statusItemsTmn2: [
        {
          text: this.$t('withdraw.status1'),
          value: 'wait',
          color: '',
        },
        // {
        //   text: this.$t('withdraw.status6'),
        //   value: 'success_1',
        //   color: 'success',
        // },
        {
          text: this.$t('withdraw.status3'),
          value: 'success_0',
          color: 'info',
        },
        {
          text: this.$t('withdraw.status4'),
          value: 'cancel',
          color: 'error',
        },
      ],
      statusItems: [
        {
          text: this.$t('withdraw.status1'),
          value: 'wait',
          color: '',
        },
        {
          text: this.$t('withdraw.status7'),
          value: 'processing',
          color: 'warning',
        },
        {
          text: this.$t('withdraw.status8'),
          value: 'success_promptpay',
          color: 'success',
        },
        {
          text: this.$t('withdraw.status9'),
          value: 'success_cloudpay',
          color: 'success',
        },
        {
          text: this.$t('withdraw.status2'),
          value: 'success_1',
          color: 'success',
        },
        {
          text: this.$t('withdraw.status3'),
          value: 'success_0',
          color: 'info',
        },
        {
          text: this.$t('withdraw.status4'),
          value: 'cancel',
          color: 'error',
        },
      ],
      statusItems2: [
        {
          text: this.$t('withdraw.status1'),
          value: 'wait',
          color: '',
        },
        {
          text: this.$t('withdraw.status7'),
          value: 'processing',
          color: 'warning',
        },
        {
          text: this.$t('withdraw.status5'),
          value: 'success',
          color: 'success',
        },
        {
          text: this.$t('withdraw.status8'),
          value: 'success_promptpay',
          color: 'success',
        },
        {
          text: this.$t('withdraw.status9'),
          value: 'success_cloudpay',
          color: 'success',
        },
        {
          text: this.$t('withdraw.status6'),
          value: 'success_2',
          color: 'success',
        },
        {
          text: this.$t('withdraw.status2'),
          value: 'success_1',
          color: 'success',
        },
        {
          text: this.$t('withdraw.status3'),
          value: 'success_0',
          color: 'info',
        },
        {
          text: this.$t('withdraw.status4'),
          value: 'cancel',
          color: 'error',
        },
      ],
      headers: [
        {
          text: this.$t('withdraw.header1'),
          value: 'username',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('withdraw.header2'),
          value: 'bank',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('withdraw.header4'),
          value: 'date',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('withdraw.header5'),
          value: 'amount',
          width: '100px',
          align: 'end',
          sortable: false,
        },
        {
          text: this.$t('withdraw.header6'),
          value: 'status',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('withdraw.header6'),
          value: 'action',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('withdraw.header7'),
          value: 'admin',
          width: '120px',
          align: 'center',
          sortable: false,
        },
      ],
      itemsData: [{ id: 21321 }],
      bankKey: this.$store.getters.getKeyBank,
      members: [],
      loading: false,
      loadingSubmit: false,
      loadingMember: false,
      loadingTable: false,
      canCreate: false,
      dummy: true,
      intervalTime: 180000,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      flag_interval: null,
      bankSystem: ''
    }
  },
  async created() {
    this.getWebSettingInfo()
    this.addLogPage()
    this.getPermissionsEdit()
    this.getColumnWithdrawAccountName()
    this.searchUser()
    this.refreshdata()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getWithdraw(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && !this.dummy)
          await this.getWithdraw(
            newValue.page,
            newValue.itemsPerPage,
            this.$refs.startDate.value,
            this.$refs.endDate.value,
            this.searchData,
          )
        if (cont && this.dummy)
          await this.getWithdraw(
            newValue.page,
            newValue.itemsPerPage,
            this.$refs.startDate.value,
            this.$refs.endDate.value,
            this.searchData,
          )
      },
      deep: true,
    },
  },
  methods: {
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
              (data = {
                ip: el.ip,
                name: 'ถอนเงิน',
                url: window.location.href,
                detail: 'ระบบจัดการถอนเงิน',
                admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
              }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'withdraw')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
      }
    },
    async getColumnWithdrawAccountName() {
      let isShowColumn = false
      await this.$store.dispatch('getSettingFeature').then(res => {
        const chkShowColumn = res.filter(item => item.column === 'withdrawAccountName')[0]?.value === 1 ? true : false
        isShowColumn = chkShowColumn
      })
      if (isShowColumn) {
        const newHeader = {
          text: this.$t('withdraw.header3'),
          value: 'bankName',
          width: '100px',
          align: 'center',
          sortable: false,
        }
        this.headers.splice(2, 0, newHeader)
      }
    },
    async getTimeRefreshWithdraw() {
      await this.$store.dispatch('getSettingFeature').then(res => {
        this.intervalTime = res.filter(item => item.column === 'withdrawRefreshTime')[0]? item.value : 180000
      })

    },
    async searchWithdraw() {
      this.dummy = false
      // let params = {
      //   time_from: this.$refs.startDate.value,
      //   time_to: this.$refs.endDate.value,
      //   ...(this.role_id ? { role_id: this.role_id } : {}),
      // }
      await this.getWithdraw(
        1,
        this.pagination.rowsPerPage,
        this.$refs.startDate.value,
        this.$refs.endDate.value,
        this.searchData,
      )
      this.pagination.page = 1
    },
    async searchUser(search) {
      this.loadingMember = true
      this.members = []
      let rs = await this.$store.dispatch('getMember', {
        page: 1,
        rows: 25,
        type: 'member',
        ...(search ? { search: search } : {}),
      })
      if (rs.count > 0) {
        this.members = []
        rs.data.forEach(element => {
          this.members.push({
            value: element.id,
            label: `${this.bankKey[element.bank] ? this.bankKey[element.bank] : element.bank} ${
              element.bank_account
            } : ${element.phone} - ${element.name}`,
          })
        })
      }
      this.loadingMember = false
    },
    async withdraw() {
      this.loadingSubmit = true
      if (this.$refs.form.validate()) {
        let params = {
          amount: 0,
          save_transtion: this.transaction,
          member_id: this.memberId,
        }
        try {
          params.amount = parseFloat(this.amount)
          await this.$store.dispatch('addWithdraw', params)
          await this.getWithdraw(this.pagination.page, this.pagination.itemsPerPage)
          this.$swal.fire('โยกเงินสำเร็จ', '', 'success')
          this.amount = ''
          this.memberId = ''
        } catch (e) {
          if (
            !e.message.includes('Unexpected token') ||
            !e?.includes('Unexpected token') ||
            !e.message.includes('Unexpected identifier')
          ) {
            const chkErr = e.message.includes('ถอนเงินออโต้ไม่สำเร็จ')
            this.$swal.fire({
              icon: chkErr ? 'warning' : 'error',
              title: e.message,
            })
          }
        }
      }
      this.loadingSubmit = false
    },
    async getWithdraw(page, row, time_from, time_to, search) {
      if (page !== 1 || search) {
        clearInterval(this.flag_interval)
      }
      try {
        this.loadingTable = true
        if (this.$route.name === 'withdraw') {
          let rs = await this.$store.dispatch('getWithdraw', {
            // time_from: this.$refs.startDate.value,
            // time_to: this.$refs.endDate.value,
            page: page,
            rows: row,
            ...(!search ? { page: page } : {}),
            ...(!search ? { rows: row } : {}),
            ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
            ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
            ...(search ? { search: search } : {}),
          })

          this.pagination.totalItems = rs.count
          this.itemsData = []
          rs.data.forEach(e => {
            let splitCountry, splitBankCode;
            if (e.member && e.member?.bank_code) {
              const code = e.member.bank_code;
              const checkCountryCode = (code) => {
                const validCodes = ['TH', 'KR', 'LA', 'TW', 'CN', 'KH', 'VN', 'MM'];
                const firstTwoChars = code.substring(0, 2);
                return validCodes.includes(firstTwoChars);
              }

              if (checkCountryCode(code)) {
                splitCountry = code.slice(0, 2);
                splitBankCode = code.slice(2);
              } else {
                splitCountry = this.bankSystem;
                splitBankCode = code;
              }

              e.member.bankCountry = splitCountry.toLowerCase();
              e.member.bank = splitBankCode;
            }

            this.itemsData.push({
              id: e.id,
              username: e.member.phone,
              amount: e.amount,
              bank: e.member.bank,
              bankCountry: e.member?.bankCountry ? e.member.bankCountry : null,
              bankAccount: e.member.bank_account,
              bankName: e.member.bank_name,
              status: `${
                e.status === 'success' && e.paykrub
                  ? 'success_promptpay' || 'success_cloudpay'
                  : e.status === 'success'
                  ? `${e.status}_${e.auto}`
                  : e.status
              }`,
              date: moment(e.created_at).format('YY-MM-DD HH:mm'),
              admin: e.admin ? e.admin.name : ' - ',
            })
          })
        } else {
          clearInterval(this.flag_interval)
        }
        this.loadingTable = false
        // this.$router.push({ name: 'bank' })
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      await this.getWithdraw(1, this.pagination.itemsPerPage, null, null, value)
      this.pagination.page = 1
    },
    async changeWithdraw(item) {
      try {
        const myArray = item.status.split('_')
        let params = {
          id: item.id,
          status: myArray[0],
          auto: myArray[1] == 1 || item.status === 'success_promptpay' || item.status === 'success_cloudpay' ? true : false,
        }
        if (item.bank === 'TRUEWALLET') {
          await this.$store.dispatch('updateWithdrawTrueWallet', params)
        } else if (item.status === 'success_promptpay') {
          await this.$store.dispatch('updateWithdrawPaykrub', params)
        } else if (item.status === 'success_cloudpay') {
          await this.$store.dispatch('updateWithdrawCloudpay', params)
        } else {
          await this.$store.dispatch('updateWithdraw', params)
        }
        this.$swal.fire('เปลี่ยนสถานะสำเร็จ', '', 'success')
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          const chkErr = e.message.includes('ถอนเงินออโต้ไม่สำเร็จ')
          this.$swal.fire({
            icon: chkErr ? 'warning' : 'error',
            title: e.message,
          })
        }
      }
    },
    async withdrawForm(item) {
      this.$router.push({ name: 'withdraw-form', params: { id: item.id } })
    },

    async preloadExportCsv() {
      //////// get logExportData ////////
      let logExportData
      const userSystem = store.getters.getuserInfo
      await fetch('https://jsonip.com/').then(res =>
        res.json().then(
          el =>
            (logExportData = {
              ip: el.ip,
              exportPage: 'ถอนเงิน - ระบบจัดการถอนเงิน',
              url: window.location.href,
              detail: 'ระบบจัดการถอนเงิน',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
        ),
      )

      this.$swal({
        title: `Secure code | รายงาน${logExportData.exportPage}`,
        input: 'password',
        // inputAttributes: {
        //   inputmode: 'numeric',
        //   pattern: '[0-9]*',
        //   maxlength: 6,
        // },
        inputValidator: value => {
          if (!value) {
            return 'โปรดกรอก Secure code'
          }
          // if (value && value.length !== 6) {
          //   return 'โปรดกรอกให้ครบ 6 หลัก'
          // }
        },
        inputPlaceholder: 'ใส่รหัสที่นี่...',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ออก',
      }).then(async result => {
        if (result.isConfirmed) {
          const pin = result.value
          let enterSecureData = {
            ...logExportData,
            keyCheck: pin,
          }
          this.loading = true
          let resEnterSecureData = await this.$store.dispatch('checkLogEnterSecure', enterSecureData)
          if (resEnterSecureData && resEnterSecureData.secure) {
            this.exportCsv()
          } else {
            this.$swal.fire('ไม่สามารถ download ได้ เนื่องจากรหัส PIN ไม่ถูกต้อง', '', 'error')
          }
          this.loading = false
        }
      })
    },
    async exportCsv() {
      this.$swal.fire({
        html: 'กำลังประมวลผล',
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      let fileName = `รายงานถอนเงิน_${moment(new Date()).format('YYYY-MM-DD_HH-mm')}`
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'
      const wscols = [{ wch: 15 }, { wch: 10 }, { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 10 }]
      let statementlist = []
      let rs = await this.$store.dispatch('getWithdraw', {
        // time_from: this.$refs.startDate.value,
        // time_to: this.$refs.endDate.value,
        page: 1,
        rows: 1000000,
        ...(this.$refs.startDate.value ? { time_from: this.formatDateYYYY(this.$refs.startDate.value) } : {}),
        ...(this.$refs.endDate.value ? { time_to: this.formatDateYYYY(this.$refs.endDate.value) } : {}),
        ...(this.searchData ? { search: this.searchData } : {}),
      })

      rs.data.forEach(element => {
        const statusConv = this.statusItems2.find(item => item.value === element.status)
        const statusConvtmn = this.statusItemsTmn.find(item => item.value === element.status)
        let isTmn = element?.member && element.member.bank === 'TRUEWALLET' ? true : false
        let statusFormated = isTmn ? statusConvtmn : statusConv

        try {
          statementlist.push({
            username: element?.member ? String(element.member.phone) : null,
            bank: element?.member ? element.member.bank : null,
            bank_account: element?.member ? element.member.bank_account : null,
            date: element?.created_at ? moment(element.created_at).format('YYYY-MM-DD HH:mm') : '',
            amount: element?.amount ? element.amount : null,
            status: element?.status ? statusFormated : null,
            admin: element?.admin?.username && element?.admin?.username,
          })
        } catch (error) {
          // console.log('Report error :>> ', error);
        }
      })
      let ws = XLSX.utils.json_to_sheet(statementlist)
      ws['!cols'] = wscols
      const wb = { Sheets: { WithdrawReport: ws }, SheetNames: ['WithdrawReport'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, fileName + fileExtension)

      // add log admin export
      await this.addLogExportCSV()
      this.$swal.close()
    },
    async addLogExportCSV() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
              (data = {
                ip: el.ip,
                exportPage: 'ถอนเงิน',
                url: window.location.href,
                detail: 'ระบบจัดการถอนเงิน',
                admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
              }),
          ),
        )
        await this.$store.dispatch('addLogExportCSV', data)
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },

    getText(value, bank) {
      const item = bank === 'TRUEWALLET' ? this.statusItemsTmn : this.statusItems
      const data = item.find(obj => obj.value === value)

      return data.text
    },
    getColor(value) {
      const data = this.statusItems.find(obj => obj.value === value)

      return data.color
    },
    refreshdata() {
      this.flag_interval = setInterval(() => {
        this.getWithdraw(1, this.pagination.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value)
      }, this.intervalTime)
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
